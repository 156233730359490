<template>
  <BaseFacility>
  <template v-slot:title>
      <h1 class="py-4 page-title-main">
      {{facilityName}}
    </h1>
  </template>
    <template v-slot:default>
      <div class="wrapper elevation-1">
        <div>
          <div>
            <v-breadcrumbs :items="items">
              <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>
          </div>
          <v-simple-table class="table-custom">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="color: #757575 !important">{{ $t("facility.settings.roomTypeID") }}</th>
                  <th style="color: #757575 !important">{{ $t("facility.settings.roomTypeName") }}</th>
                  <th></th>
                  <th style="color: #757575 !important">{{ $t("facility.settings.roomTypeEditing") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in roomTypeList" :key="item.id">
                  <td style="color: #757575 !important">{{ item.id }}</td>
                  <td style="color: #424242 !important; font-size:15px !important">{{ item.name }}</td>
                  <td>
                    <v-btn
                      :disabled="!checkPerUser"
                      small
                      color="#3083DC"
                      @click="updateSelectedRoomType(item.id, item.name)"
                      ><span style="font-size:14px; color: #fff">
                        各部屋設定</span></v-btn
                    >
                  </td>
                  <td>
                    <v-btn
                      class="mx-2 pencil"
                      fab
                      dark
                      small
                      :disabled="!checkPerUser"
                      @click="checkPerUser ? editItemFac(item.id) : ''"
                    >
                      <v-icon :disabled="!checkPerUser" color="#3083DC" style="font-size:22px">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-right mt-3">
            <v-btn color="#004F8B" :disabled="!checkPerUser" @click="checkPerUser ? openAddDialog = true : ''">
              <span style="color: #fff">
              {{
              $t("buttons.createNewFacility")
            }}</span></v-btn>
          </div>
        </div>
      </div>
      <base-dialog
        width="500px"
        :visible="openAddDialog"
        @close="closeDialog"
        :title="$t('facility.settings.createNewRoomType')"
        isGrayDialog
      >
        <template v-slot:content>
          <v-form ref="formSubmit" lazy-validation class="d-flex mt-5 pr-5">
            <label class="mr-3" style="color: #666666; font-size:16px">{{
              $t("facility.settings.roomTypeNameManagement")
            }}</label>
            <v-text-field
              dense
              outlined
              :rules="rulesRoomType.name"
              class="pa-0 mt-3"
              v-model="roomTypeName"
            ></v-text-field>
          </v-form>
        </template>

        <template v-slot:footer>
          <div class="text-right px-5">
            <v-btn
              :disabled="!checkPerUser"
              large
              class="btn--pink mr-5"
              @click="closeDialog"
              >{{ $t("buttons.cancel") }}</v-btn
            >
            <v-btn :disabled="!checkPerUser" color="#004F8B" large @click="handleSubmit">
              <span style="color: #fff">{{
              $t("buttons.saveAndCreate")
            }}</span>
            </v-btn>
          </div>
        </template>
      </base-dialog>
    </template>
  </BaseFacility>
</template>

<script>
import BaseFacility from '@/components/Facility/BaseFacility'
import BaseDialog from '@/components/Dialog/BaseDialog'
import { FACILITY_LIST } from '@/api/graphql/facility-settings'
import { ROOM_TYPE_LIST } from '@/api/graphql/facility/facility-room-type'
import { mapState, mapActions, mapMutations } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import gql from 'graphql-tag'

export default {
  name: 'roomType',

  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      facilityName: '',
      facilityList: [],
      roomTypeList: [],
      facilityId: parseInt(this.$route.query.id),
      rulesRoomType: {
        name: [v => !!v || this.$t('rules.isRequired'),
          v => v?.length < 255 || this.$t('rules.isInvalid')
        ]
      },
      items: [
        {
          text: this.$t('facility.settings.roomType'),
          disabled: false,
          href: '/facility/settings'
        },
        {
          text: this.$t('facility.settings.roomTypeDetail'),
          disabled: false
        }
      ],

      // ======= DIALOG =======
      openAddDialog: false,
      roomTypeName: ''
    }
  },

  computed: {
    ...mapState({
      selectedFacility: state => state.facilitySettings.selectedFacility
    })
  },

  mounted () {
    this.getRoomTypeList()
    this.getFacility()
  },

  methods: {
    ...mapActions({
      createRoomType: 'createRoomType',
      actionUpdateSelectedRoomType: 'updateSelectedRoomType'
    }),
    ...mapMutations({ setFacilityRoomType: 'setFacilityRoomType' }),

    async getRoomTypeList () {
      await this.$apollo
        .query({
          query: gql`${ROOM_TYPE_LIST}`,
          variables: {
            facilityId: this.facilityId
          }
        })
        .then((data) => {
          this.roomTypeList = data.data.roomTypeList
        })
        .catch((error) => {
          console.error(error)
        })
    },

    async getFacility () {
      await this.$apollo.query({
        query: gql`${FACILITY_LIST}`
      }).then((data) => {
        const facilityItem = (data.data.facilityList).filter((item) => item.id === parseInt(this.$route.query.id))
        this.facilityName = facilityItem[0].name
        this.items[1].text = this.facilityName
        document.title = this.facilityName
      }).catch((error) => {
        console.error(error)
      })
    },

    handleSubmit () {
      if (this.$refs.formSubmit.validate()) {
        const facilityRoomType = {
          id: this.facilityId,
          name: this.roomTypeName
        }
        this.setFacilityRoomType({ facilityRoomType: facilityRoomType })
        this.createRoomType()
      }
    },

    editItemFac (id) {
      // :to="'/facility/settings/room-type/detail?id=' + item.id"
      const routeData = `/facility/settings/room-type/detail?id=${id}`
      window.open(routeData, '_blank')
    },

    updateSelectedRoomType (roomTypeId, roomTypeName) {
      if (this.checkPerUser) {
        this.actionUpdateSelectedRoomType(roomTypeName)
        this.$router.push({
          path: `/facility/settings/room-type/rooms?id=${roomTypeId}&roomTypeId=${this.$route.query.id}`
        })
      }
    },

    closeDialog () {
      this.$refs.formSubmit.reset()
      this.openAddDialog = false
    }
  },

  components: {
    BaseFacility,
    BaseDialog
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 600px;
  padding: 20px;
  background: var(--ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 5px #21212131 !important;
  border-radius: 4px;
  opacity: 1;
}
.heading {
  font-size: 18px;
}
::v-deep {
  .v-data-table {
    tbody tr td {
      text-align: center;
    }
  }
  .page-title-sub,
  .v-breadcrumbs__item,
  .v-breadcrumbs__divider {
    font-weight: bold !important;
    color: #aaaaaa !important;
    font-size: 18px !important;
  }
  .v-breadcrumbs li:nth-child(even) {
    padding: 0;
  }
  .v-breadcrumbs li:nth-child(2n) {
    padding: 0;
  }
  .mdi-chevron-right::before {
    font-weight: bold;
  }
  .v-icon {
    font-size: 22px;
  }
}
.pencil {
  background: var(--ffffff) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  opacity: 1;
  height: 28px;
  width: 28px;
  color: #3083dc !important;
  &.--disabled {
    background: #ccc !important;
  }
}
</style>
